window.etoile = window.etoile || {};
etoile.Burger = function Burger($) {
  'use strict';

  /**
   * jQuery elements.
   * @private
   */
  var $burgerBtn = $('.js-burger-btn');
  var $burgerMenu = $('.js-burger-menu');

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    /**
     * Call to open the menu
     */

    $burgerBtn.on('click', function (e) {
      var $this = $(this);
      toggleMenu($this);
      e.stopPropagation();
    });
    inited = true;

    // return success
    return true;
  };

  /**
   * Open or Close the Menu
   * @private
   */

  var toggleMenu = function (e) {
    if (e.hasClass('is-active')) {
      e.removeClass('is-active');
      $('body').removeClass('overlay');
      $burgerMenu.slideUp('medium');
    } else {
      $('body').addClass('overlay');
      e.addClass('is-active');
      $burgerMenu.slideDown('medium');
      var currentH = $('.overlay-div').height();
      $('.overlay-div').height(currentH + $('footer').outerHeight());
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);