window.etoile = window.etoile || {};

/**
 * Utilities for interacting with the application.
 * @class App
 * @static
 */
etoile.App = function App($) {
  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;
  var $fixed = $('.js-fixed');
  var $fixedMenu = $('.js-fixed-menu');

  /**
   * Application config defaults.
   * @private
   * @param {Object} config.env     Current server environment
   * @param {Object} config.locale  Current locale short code
   */
  var config = {
    env: 'local',
    locale: 'fr',
    csrf: null
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function (options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Store application settings
    options = options || {};
    if (options.env) {
      config.env = options.env;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    checkViewport();
    carouselCardsHeight();
    if ($('#pano').length) {
      embedPano();
    }
    if (checkViewport() != 'small') {
      initHeight();
      var parentsHeight = $('footer').offset().top - ($('header').outerHeight() + $('footer').outerHeight());
      $fixed.scrollToFixed({
        limit: parentsHeight - 223,
        marginTop: 123,
        fixed: function () {
          $('.scroll-to-fixed-fixed').height();
        },
        unfixed: function () {
          $('.scroll-to-fixed-fixed').css('bottom', '0').css('top', 'initial');
        }
      });
    }
    $(window).on('scroll', function () {
      var scrollPos = $(this).scrollTop();
      if (scrollPos >= 1) {
        $fixedMenu.addClass('fixed-state');
      } else {
        $fixedMenu.removeClass('fixed-state');
      }
    });
    if (typeof etoile.Carousel === 'object') {
      etoile.Carousel.init();
    }
    if (typeof etoile.Burger === 'object') {
      etoile.Burger.init();
    }
    if (typeof etoile.Generic === 'object') {
      etoile.Generic.init();
    }
    if (typeof etoile.Search === 'object') {
      etoile.Search.init();
    }
    if (typeof etoile.Form === 'object') {
      etoile.Form.init();
    }
    $('.js-scrollbar').mCustomScrollbar({
      theme: "dark",
      scrollbarPosition: 'outside',
      scrollInertia: 300
    });

    // Return success
    return true;
  };

  /**
   * Check Viewport to enable certain callbacks
   * @private
   */
  var checkViewport = function () {
    var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (width < 768) {
      return 'small';
    } else if (width >= 768 && width < 1024) {
      return 'medium';
    } else {
      return 'large';
    }
  };

  /**
   * matchHeight Function - Keep divs heights to same height
   * @private
   */
  var initHeight = function () {
    $('.js-height, .js-cards, .show-more-wrapper').matchHeight({
      byRow: false
    });
  };
  var carouselCardsHeight = function () {
    $('.js-cards-carousel').matchHeight({
      byRow: false
    });
  };
  var embedPano = function () {
    embedpano({
      swf: "/assets/tour/tour.swf",
      xml: "/assets/tour/tour.xml",
      target: "pano",
      html5: "auto",
      mobilescale: 1.0,
      passQueryParameters: true
    });
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function (option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    getConfig: getConfig,
    checkViewport: checkViewport
  };
}(jQuery);