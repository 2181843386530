window.etoile = window.etoile || {};
etoile.Generic = function Generic($) {
  'use strict';

  /**
   * jQuery elements.
   * @private
   */
  var $accordeon = $('.js-faq');
  var $content = $('.js-faq-content');

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    $('.opened').find($content).slideDown();
    $accordeon.on('click', function () {
      accordeonFaq($(this));
    });

    // return success
    return true;
  };

  //ACCORDEON
  var accordeonFaq = function (e) {
    if (e.hasClass('opened')) {
      e.removeClass('opened').find($content).slideUp();
    } else {
      e.addClass('opened').find($content).slideDown();
    }
    $accordeon.each(function () {
      if ($(this).not(e).hasClass('opened')) {
        $(this).not(e).removeClass('opened').find($content).slideUp();
      }
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);